import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CallService } from 'src/@shared/services/call.service';
import { SupersetService } from 'src/@shared/services/superset.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  storedWindowObj:any;

  constructor(
    private title: Title,
    private supersetService:SupersetService,
  ) {
  }
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event) {
    this.storedWindowObj = this.supersetService.storedWindowObj;
    for(let i=0; i<this.storedWindowObj?.length; i++){
      if(this.storedWindowObj[i]) {
        this.storedWindowObj[i].close();
      }
    }
  }

  ngOnInit() {
    this.title.setTitle(environment.title || 'AuditNex');
  }
}
